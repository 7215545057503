import React from 'react';
import Helmet from 'react-helmet';
import { Flex, Box, Text } from 'rebass';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { graphql } from 'gatsby'

import Layout from '../components/Layout';

export const query = graphql`query AboutUsQuery {
  store: site {
    siteMetadata {
      gatsbyStorefrontConfig {
        storeName
        storeDescription
      }
    }
  }
}
`

function Home({ data, location }) {
  const {
    storeName,
    storeDescription,
   } = data.store.siteMetadata.gatsbyStorefrontConfig
  const { title } = `About us — ${storeName}`

  return (
    <Layout>
     <GatsbySeo
        title={title}
        description={storeDescription}
        openGraph={{
          url: location.href,
          title: title,
          description: storeDescription,
          images:[{
            url: location.origin + '/images/logo_800_276.png',
            width: 800,
            height: 276,
          },
          {
            url: location.origin + '/images/logo_800_800.png',
            width: 800,
            height: 800,
          },
          {
            url: location.origin + '/images/logo_382_113.png',
            width: 382,
            height: 113,
          }],
        }}
      />
      <Helmet title={`About ${storeName}`} defer={false} />
      <Flex px={2} pt={3} mx="auto" justifyContent='center' flexWrap="wrap">
        <Box
          pt={3}
          width={[1, 2 / 3]}
        >
            <Text
              as="h1"
              fontSize={4}
              color="primary"
              lineHeight={1}
            >
              {storeName} - We are a brand with integrity
            </Text>

            <Text as="h3" fontSize={4} color="primary" lineHeight={1.5} mt={3}>
              Transform and play!
            </Text>
            <Text as="h4" fontSize={3} color="primary" lineHeight={1.5} mt={3}>
              We want you to have your cake and eat it to!
            </Text>
            <Text as="p" py={3} color="primary" lineHeight={1.5}>
              This is why a lot of our garments can be worn different ways.
              You will have several garments in one and have the freedom to
              transform it.
            </Text>

            <Text as="h4" fontSize={3} color="primary" lineHeight={1.5} mt={3}>
              We know you probably are a ladyboss, or one in the making - so time is of the essence
              and you are not about to miss out of any adventures!
            </Text>
            <Text as="p" py={3} color="primary" lineHeight={1.5}>
              With that in mind - we design our pieces so they can be appropriate at work and still make you feel dressed up
              to go out straight after. Just bring your killer heels.
            </Text>

            <Text as="h4" fontSize={3} color="primary" lineHeight={1.5} mt={3}>
              We know you want to look cool, but still have the comfy feeling you have in your PJ`s!
            </Text>
            <Text as="p" py={3} color="primary" lineHeight={1.5}>
              So, this is why we focus on interesting designs combined with quality,
              often natural and comfortable fabrics. If you feel better, you do better
              - right?
            </Text>

            <Text as="h4" fontSize={3} color="primary" lineHeight={1.5} mt={3}>
              ...and at the same time don't ruin the planet!
            </Text>
            <Text as="p" py={3} color="primary" lineHeight={1.5}>
              Our collections are non-season and together with the
              combination of classic colors, versatility, quality and timeless design
              - the garments are made to last. The main fabric we use is Tencel/lyocell.
              Tencel is biodegradable. It is an incredible silky and soft fabric, which
              is sustainably regenerated from wood cellulose and is one of the most
              environmental friendly regenerated fabrics.            
              For our leather garments, we only use cow or sheepskin leather. These are leathers where the
              whole animal is utilized. And natural leather is also biodegradeable.
            </Text>
            <Text as="h4" fontSize={3} color="primary" lineHeight={1.5} mt={3}>
              TDE Studio
            </Text>
            <Text as="p" py={3} color="primary" lineHeight={1.5}>
              Our TDE Studio line is either redesign and/or upcycled from deadstock garments or designed,
              modelled and made in-house here in Oslo. These items are all limited editions. 
            </Text>

            <Text as="h3" fontSize={4} color="primary" lineHeight={1.5} mt={3}>
              Contact
            </Text>
            <Text as="p" py={3} color="primary" lineHeight={1.5} mt={0}>
              Too Damn Expensive AS was officially registered 4th February 2014, and are based in Oslo, Norway.
            </Text>
            <address>
              <Text as="h4" fontSize={3} color="primary" lineHeight={1.5} mt={0}>
                Too Damn Expensive AS
              </Text>
              <Text as="p" color="primary" lineHeight={1.5}>
                Toftes gate 43<br />
                0552 Oslo<br />
                Norway<br />
                <a href="mailto:contact@toodamnexpensive.com">contact@toodamnexpensive.com</a><br/>
                <a href="https://www.instagram.com/chrlieblck/" target="_blank">Send a direct message on Instagram</a><br/>
                Organization number: NO 913 284 348 MVA
              </Text>
            </address>
          </Box>
      </Flex>
    </Layout>
  );
}

export default Home;
